<template>
  <!-- Under maintenance-->
  <div class="misc-wrapper">
    <b-button
      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      v-b-modal.modal-center
      variant="warning"
      class="mx-1"
    >
      S'inscrire
    </b-button>

    <!-- Modal -->
    <b-modal
      id="modal-center"
      centered
      title="Vertically Centered"
      hide-footer
      hide-header
    >
      <!-- <b-link class="brand-logo">
        <AppLogo />
      </b-link> -->

      <div class="misc-inner p-2 p-sm-3">
        <div class="w-100 text-center">
          <h1 class="mb-2">S'inscrire en tant que</h1>

          <!-- <p class="mb-3">
          Sorry for the inconvenience but we're performing some maintenance at
          the moment
        </p> -->
          <b-row class="match-height">
            <b-col
              v-for="(item, i) in registrationTypes"
              :key="'item' + i"
              md="12"
              sm="12"
              class="kb-search-content"
            >
              <b-card @click="$router.push({ ...item.route })">
                <div class="form-item">
                  <div class="icon-container">
                    <i class="text-primary" :class="item.remixIcon" />
                  </div>

                  <div>
                    <h4 class="form-title">
                      {{ item.title }}
                    </h4>
                    <!-- <b-card-text class="mt-1">
                    {{ item.desc }}
                  </b-card-text> -->
                  </div>
                </div></b-card
              >
            </b-col>
          </b-row>
        </div>
      </div>
    </b-modal>
  </div>
  <!-- / Under maintenance-->
</template>

<script>
/* eslint-disable global-require */
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BLink,
  BFormInput,
  BButton,
  BForm,
  BImg,
  BModal,
  VBModal,
} from "bootstrap-vue";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import store from "@/store/index";
import AppLogo from "@/components/AppLogo.vue";
import Ripple from "vue-ripple-directive";

const loremText =
  "Lorem ipsum dolor sit amet consectetur adipisicing elit. Perspiciatis, culpa. Voluptatibus natus aperiam neque libero dolor, recusandae velit voluptatum fugit, molestiae perferendis officia? Culpa quisquam ducimus possimus porro ipsa! Excepturi.";

export default {
  components: {
    BLink,
    BFormInput,
    BButton,
    BForm,
    BImg,
    BRow,
    BCol,
    BCard,
    BCardBody,
    VuexyLogo,
    AppLogo,
    BModal,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      downImg: require("@/assets/images/pages/under-maintenance.svg"),
      registrationTypes: [
        {
          title: "Etablissements ou agence de voyage",
          desc: loremText,
          route: { name: "operateurs-create" },
          remixIcon: "ri-hotel-fill",
        },
        {
          title: "Voyageur/Client/Touriste",
          desc: loremText,
          route: { name: "register-default" },
          remixIcon: "ri-user-fill",
        },
      ],
    };
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require("@/assets/images/pages/under-maintenance-dark.svg");
        return this.downImg;
      }
      return this.downImg;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/pages/page-misc.scss";
.form-item {
  cursor: pointer;
  display: flex;
  gap: 15px;
  align-items: center;
}
.icon-container {
  min-height: 50px;
  height: 50px;
  min-width: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  background-color: rgba($color: green, $alpha: 0.5);
  border-radius: 100px;
}
</style>
